import { Draggable } from 'agnostic-draggable';

export default class PlanbookDraggable extends Draggable {
  constructor(element, options = {}, listeners = {}) {
    super(element, options, listeners);
  }

  calculatePosition(event, constraint = true) {
    super.calculatePosition(event, constraint);
    const cursorAt = this.options.cursorAt;
    if (cursorAt) {
      this.adjustOffsetFromHelper(cursorAt);
    }
  }

  adjustOffsetFromHelper(obj) {
    if (typeof obj === 'string') {
      obj = obj.split(' ');
    }
    if (Array.isArray(obj)) {
      obj = { left: +obj[0], top: +obj[1] || 0 };
    }
    this.offset.click.left = ('left' in obj ? obj.left : 0) + (this.margins.left ? this.margins.left : 0);
    this.offset.click.left = (this.helperSize.width ? this.helperSize.width : 0) - ('right' in obj ? obj.right : 0) + (this.margins.left ? this.margins.left : 0);
    this.offset.click.top = ('top' in obj ? obj.top : 0) + (this.margins.top ? this.margins.top : 0);
    this.offset.click.top = (this.helperSize.height ? this.helperSize.height : 0) - ('bottom' in obj ? obj.bottom : 0) + (this.margins.top ? this.margins.top : 0);
  }

  destroy() {
    super.destroy();
    document.removeEventListener('mouse:down', this.onMouseDown);
  }
}
